/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/promise-polyfill@8.1.0/dist/polyfill.min.js
 * - /npm/mdn-polyfills@5.19.0/Array.prototype.fill.js
 * - /npm/classlist-polyfill@1.2.0/src/index.js
 * - /npm/@webcomponents/webcomponentsjs@2.4.2/webcomponents-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
